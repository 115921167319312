.hs-confirmation {
  /* Content */
  padding: 40px;
  background-color: white;
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  margin: auto;
  margin-top: 250px;
  max-width: 450px;
}

.hs-confirmation-wrapper {
  z-index: 9999999999;
}

.hs-confirmation-bg {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999999;
}

.hs-confirmation-header {
  display: flex;
  width: 100%;
}

.hs-confirmation-close {
  margin-left: auto;
}

.hs-confirmation-body {
  display: inline-block;
  width: 100%;
}

.hs-confirmation-buttons {
  display: flex;
}

.hs-confirmation-header > h1 {
  font-family: Aventa;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 29px;
  color: #0b0c10;
}

.hs-confirmation-description {
  font-family: Avenir;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #646a7c;
  text-align: left;
  word-break: break-word;
}
